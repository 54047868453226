import React from "react";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import MissionContent from "../../components/team/mission-content";
import MyltFaq from "../../components/mylt-faq";

const Mission = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | Why MYLT">
    <HeaderTwo />
    <div className="mission-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="Why MYLT" crumbTitle="Why MYLT" />
      <MyltFaq />
    </div>
    <Footer />
  </Layout>
);

export default Mission;
